import React from "react";
import "./App.scss";
import { Logo } from "./components/Logo";
import { tabs } from "./config/tabs";
import { Button } from "./components/Button";
import { WidgetsIntroduction } from "./components/WidgetsIntroduction";
import { CheckIcon } from "./components/CheckIcon";
import { FaqSection } from "./components/FaqSection";

function App() {
  return (
    <div>
      <div className="bg-gradient-to-b from-[#FEFEFF] to-[#EAEAFF] min-h-[600px] h-0 overflow-hidden relative mb-24">
        <div className="container mx-auto max-w-7xl flex flex-col justify-between h-full">
          <div className="flex justify-between items-center pt-8">
            <div className="flex gap-8 items-center">
              <Logo />
              <ul className="flex gap-6 items-center">
                {tabs.map((tab, index) => (
                  <li key={index}>
                    <a
                      href={tab.link}
                      className="text-black/90 hover:text-[#000000] font-medium"
                    >
                      {tab.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <a href={process.env.REACT_APP_DASHBOARD_URL}>
                <Button type="outline">ورود / ثبت نام</Button>
              </a>
            </div>
          </div>

          <div className="flex justify-between items-center pb-16">
            <div className="w-1/2">
              <div className="bg-[#9d91ff33] rounded-[43px] text-xs text-[#6650FF] mb-3 h-8 inline-flex items-center justify-center px-4">
                نسخه جدید
              </div>
              <h1 className="text-black text-3xl font-black max-w-[420px] mb-3 leading-relaxed">
                در نسخه جدید دونو راحت تر از همیشه حمایت مالی دریافت کنید.
              </h1>
              <p className="max-w-[460px] mb-4 text-black/76 leading-[1.9]">
                دونو بازنویسی شده با سیستم جدید صد ها قابلیت جدید را برای
                استریمر های تمامی پلتفرم های استریمینگ به صورت کامل رایگان فراهم
                میکند. در جدید ترین نسخه دونو قابلیت هایی به پلتفرم اضافه شده که
                در هیچ یک از پلتفرم های مشابه قابل انجام نیست.
              </p>
              <div className="flex gap-4">
                <a href={`${process.env.REACT_APP_DASHBOARD_URL}/login`}>
                  <Button width="120px" type="primary">
                    ثبت نام
                  </Button>
                </a>

                <Button type="outline">بیشتر بدانید</Button>
              </div>
            </div>
            <div className="w-1/2">
              <div className="-mb-[68px] absolute bottom-0">
                <img
                  src={require("./images/hero.svg").default}
                  alt="نسخه جدید دونو"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-20">
        <WidgetsIntroduction />
      </div>

      <div className="container max-w-7xl mx-auto mb-20">
        <div className="my-1 h-px bg-[#E8E8F3] w-full" />
      </div>

      <div className="mb-4">
        <div className="flex items-center justify-between container mx-auto max-w-7xl">
          <div className="w-1/2">
            <h2 className="font-extrabold text-2xl text-black mb-3">
              صفحه دونیت جدید، قوی تر از همیشه!
            </h2>
            <p className="text-black/76 leading-[1.9] mb-4 max-w-[550px]">
              در نسخه جدید صفحه پروفایل به طور کامل از ابتدا بازنویسی شده است.
              اولین قابلیتی که به چشم میخورد کاور است که به سمت چپ صفحه اضافه
              شده است. همچنین قابلیت نمایش هدف حمایت نیز به این صفحه اضافه شده
              است. علاوه بر این موارد برای راحتی کاربران قابلیت های دیگری نیز
              اضافه شده است.
            </p>
            <div className="flex gap-4">
              <a href={`${process.env.REACT_APP_DASHBOARD_URL}/login`}>
                <Button type="primary">مشاهده صفحه حمایت</Button>
              </a>

              <Button type="secondary">تمامی قابلیت ها</Button>
            </div>
          </div>
          <div className="w-1/2 text-left">
            <img
              src={require("./images/donation-page-hero.png")}
              alt="صفحه دونیت جدید"
              className="inline"
            />
          </div>
        </div>
      </div>

      <div className="mb-8 overflow-hidden">
        <div className="flex items-center justify-between container mx-auto max-w-7xl">
          <div className="w-1/2 text-left relative py-20">
            <img
              src={require("./images/media-request-hero.png")}
              alt="سیستم پخش ویدیو با دونیت"
            />
            <div className="absolute top-8 left-[420px] w-[1300px] h-full flex items-center justify-center z-[-1]">
              <img src={require("./images/media-request-shape.svg").default} />
            </div>
          </div>
          <div className="w-1/2 pt-12">
            <h2 className="font-extrabold text-2xl text-black mb-3">
              از طریق دونیشن ویدیو دریافت کنید
            </h2>
            <p className="text-black/76 leading-[1.9] mb-4 max-w-[550px]">
              با استفاده از دونو میتونید از طریق دونیت ها لینک یوتیوب دریافت
              کنید و به ازای مقدار تعریف شده (به عنوان مثال هر هزار تومان) تعداد
              مشخص شده ای از تایم ویدیو (مثلا 10 ثانیه) را مشاهده کنید. دونو
              تمامی ویدیو ها را با تایم مشخص شده برای شما جمع آوری کرده و به شما
              قابلیت پخش آن را می دهد.{" "}
            </p>
            <div className="flex gap-4">
              <a href={`${process.env.REACT_APP_DASHBOARD_URL}/login`}>
                <Button type="primary" width="120px">
                  ایجاد اکانت
                </Button>
              </a>

              <Button type="secondary">تمامی قابلیت ها</Button>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-20">
        <div className="flex items-center justify-between container mx-auto max-w-7xl">
          <div className="w-1/2 pt-8">
            <h2 className="font-extrabold text-2xl text-black mb-7">
              قابلیت های پلتفرم دونو
            </h2>
            <ul className="text-black/76 leading-[1.9] mb-7 max-w-[550px] flex flex-col gap-3">
              <li className="flex items-center gap-4">
                <CheckIcon />
                <span>
                  حمایت مالی در تمامی پلتفرم های استریم مثل توییچ، آپارات و غیره
                </span>
              </li>
              <li className="flex items-center gap-4">
                <CheckIcon />
                <span>دریافت سابسکرایب آنی و پنل مجزا برای مشاهده ساب ها</span>
              </li>
              <li className="flex items-center gap-4">
                <CheckIcon />
                <span>امکان اتصال به پلتفرم های دیگر مثل StreamElements</span>
              </li>
              <li className="flex items-center gap-4">
                <CheckIcon />
                <span>
                  امکان افزودن و شخصی سازی ویجت های جذاب برای اولین بار
                </span>
              </li>
              <li className="flex items-center gap-4">
                <CheckIcon />
                <span>
                  امکان توسعه دادن و افزودن ویجت های جدید با دانش HTML/CSS
                </span>
              </li>
            </ul>

            <div className="flex gap-4">
              <a href={`${process.env.REACT_APP_DASHBOARD_URL}/login`}>
                <Button type="primary">مشاهده تمامی قابلیت ها</Button>
              </a>

              <Button type="secondary">ثبت نام در دونو</Button>
            </div>
          </div>
          <div className="w-1/2 text-left">
            <img
              src={require("./images/features-hero.png")}
              alt="صفحه دونیت جدید"
              className="inline"
            />
          </div>
        </div>
      </div>

      <div className="mb-28">
        <FaqSection />
      </div>

      <div className="my-1 h-px bg-[#E8E8F3] w-full mb-14" />

      <div className="container mx-auto max-w-7xl mb-14">
        <div className="grid lg:grid-cols-4">
          <div className="flex flex-col">
            <div className="flex items-center gap-4 font-black text-[#6650FF] mb-2">
              <Logo />
              <span className="relative top-1">دونو</span>
            </div>
            <p className="text-black/70 leading-[1.9] text-sm">
              برترین پلتفرم جمع آوری دونیت در ایران
            </p>
          </div>
          <div className="flex flex-col xl:pr-10">
            <h3 className="font-bold text-black mb-4">دونو</h3>
            <ul className="text-black/70 leading-[1.9] text-sm flex flex-col gap-1.5">
              <li>درباره ما</li>
              <li>تماس با ما</li>
              <li>قوانین و مقررات</li>
              <li>سوالات متداول</li>
            </ul>
          </div>
          <div className="flex flex-col xl:pr-10">
            <h3 className="font-bold text-black mb-4">پشتیبانی</h3>
            <ul className="text-black/70 leading-[1.9] text-sm flex flex-col gap-1.5">
              <li>پشتیبانی</li>
              <li>راهنمای استفاده</li>
              <li>تماس با پشتیبانی</li>
            </ul>
          </div>
          <div className="flex gap-2 items-center">
            <img src={require("./images/namad.png")} alt="نماد اعتماد" />
            <img src={require("./images/samandehi.png")} alt="نماد اعتماد" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
