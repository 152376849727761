export const tabs = [
  {
    name: "سابسکرایب",
    link: "/subscribe",
  },
  {
    name: "بیت توییچ",
    link: "/bit-twitch",
  },
  {
    name: "فروشگاه",
    link: "/shop",
  },
  {
    name: "تماس با ما",
    link: "/contact",
  },
];
