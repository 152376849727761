export const Logo = () => {
  return (
    <svg
      width="32"
      height="41"
      viewBox="0 0 32 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.8571 8.94547C14.0512 8.94547 13.2765 9.13185 12.6758 9.47005L2.18162 15.3789C0.937879 16.0792 0 17.6634 0 19.064V30.8816C0 32.2822 0.937879 33.8662 2.18153 34.5665L12.6758 40.4755C13.2765 40.8137 14.0512 41 14.8571 41C15.6631 41 16.4379 40.8137 17.0387 40.4755C17.0398 40.4748 17.0409 40.4742 17.0421 40.4735L27.5327 34.5665C28.7764 33.8664 29.7143 32.2822 29.7143 30.8816V19.064C29.7143 17.6634 28.7764 16.0792 27.5328 15.379L17.0387 9.47005C16.4379 9.13185 15.6631 8.94547 14.8571 8.94547ZM10.432 29.243C10.2321 29.1285 10.0341 29.0144 9.83856 28.9003C9.36537 28.5972 8.91429 27.8076 8.91429 27.2649V23.371C8.91429 23.1203 8.91506 22.8719 8.91714 22.626C8.94606 22.0682 9.40984 21.2875 9.88337 21.0164L13.3104 19.0549C13.5207 18.9345 13.7295 18.8158 13.9368 18.6994C14.1675 18.5831 14.5053 18.514 14.857 18.514C15.2339 18.514 15.5962 18.5926 15.8262 18.7242L19.2993 20.7121C19.493 20.8232 19.6853 20.9341 19.8752 21.0449C20.3485 21.3477 20.8 22.1376 20.8 22.6806V26.6476C20.7997 26.8727 20.799 27.0962 20.7972 27.3176C20.7693 27.8754 20.3051 28.6577 19.8307 28.9291L16.3597 30.9159C16.1648 31.0273 15.9712 31.1373 15.7786 31.2455C15.5479 31.3621 15.2097 31.4315 14.857 31.4315C14.4802 31.4315 14.1179 31.3529 13.8879 31.2212L10.432 29.243Z"
        fill="url(#paint0_linear_236_81)"
      />
      <path
        d="M32 0V28.5792C32 30.6408 30.9118 32.5562 29.1221 33.6444L24.381 36.5273V7.45455C24.381 3.33751 27.7921 0 32 0Z"
        fill="url(#paint1_linear_236_81)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_236_81"
          x1="14.8571"
          y1="8.94547"
          x2="14.8571"
          y2="41"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CDAAFF" />
          <stop offset="1" stop-color="#7177FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_236_81"
          x1="28.4615"
          y1="-13.3905"
          x2="18.8572"
          y2="42.4184"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#CDAAFF" />
          <stop offset="1" stop-color="#717FFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
