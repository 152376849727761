export const FaqItem = ({
  title,
  value,
  active,
}: {
  title: string;
  value?: string;
  active?: boolean;
}) => {
  return (
    <div
      className="flex items-center gap-4 bg-white border border-[#F2F2F9] px-6 py-4 rounded-md"
      style={{
        boxShadow: "0px 4px 7px 0px rgba(0, 0, 0, 0.02)",
      }}
    >
      <div className="flex flex-col grow">
        <div className="flex items-center gap-4">
          <h3 className="flex grow font-medium">{title}</h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="7"
            viewBox="0 0 11 7"
            fill="none"
            className={active ? "transform rotate-180" : ""}
          >
            <path
              opacity="0.477463"
              d="M10 1L5 6L0 1"
              stroke="black"
              stroke-width="1.42857"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        {active && (
          <p className="flex-grow-0 text-black/70 mt-4 text-sm">{value}</p>
        )}
      </div>
    </div>
  );
};
