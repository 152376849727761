import { useState } from "react";
import { Button } from "./Button";

const tabs = [
  {
    title: "هشدار حمایت",
    id: "alert",
  },
  {
    title: "هدف حمایت",
    id: "goal",
  },
  {
    title: "ساباتون",
    id: "subathon",
  },
  {
    title: "HTML/CSS",
    id: "html-css",
  },
];

export const WidgetsIntroduction = () => {
  const [selectedTab, setSelectedTab] = useState(tabs[1].id);

  return (
    <div className="flex items-center container mx-auto max-w-7xl">
      <div className="w-1/2">
        <div className="h-[228px] overflow-hidden">
          <img
            src={require("../images/widget-system-hero.svg").default}
            alt="نسخه جدید دونو"
          />
        </div>
      </div>
      <div className="w-1/2">
        <h2 className="font-extrabold text-2xl text-black mb-3">
          سیستم ویجت اختصاصی دونو
        </h2>
        <p className="text-black/76 leading-[1.9] mb-4">
          در نسخه جدید سیستم ویجت اختصاصی به صورت کاملا مستقل از Streamelements
          توسط تیم دونو توسعه یافته است. این سیستم به کاربران تمامی پلتفرم ها
          اعم از توییچ، یوتوب، آپارات، اینستاگرام و غیره قابلیت دریافت دونیشن و
          نمایش آن در لایو استریم را می دهد. ویجت ها کاملا قابل سفارشی سازی
          (Customize) هستند و به همین علت امکان خلق ویجت های بی نهایت متفاوت را
          ممکن می سازد.
        </p>
        <div className="flex gap-5 mt-4">
          {tabs.map((tab, index) => (
            <Button
              key={index}
              onClick={() => setSelectedTab(tab.id)}
              type={selectedTab === tab.id ? "secondary" : "option"}
            >
              {tab.title}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};
