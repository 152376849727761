import { classNames } from "../utils/classNames";

export const Button = ({
  type = "primary",
  children,
  width = undefined,
  onClick,
}: {
  type?: "primary" | "secondary" | "outline" | "disabled" | "option";
  children: React.ReactNode;
  width?: string;
  onClick?: () => void;
}) => {
  return (
    <button
      onClick={onClick}
      style={{
        width,
        boxShadow:
          type === "secondary"
            ? "0px 2px 2px 0px rgba(57, 66, 83, 0.06)"
            : undefined,
      }}
      className={classNames(
        "text-[15px] px-4 h-12 rounded-lg font-medium flex items-center justify-center leading-loose",
        type === "outline" &&
          "border border-[#7E79FF] text-[#7E79FF] hover:bg-[#7E79FF] hover:text-white",
        type === "primary" &&
          "bg-gradient-to-b from-[#7473FF] to-[#B59FFF] text-white hover:from-[#6c6cfe] hover:to-[#ae96fa]",
        type === "secondary" && "bg-white text-black border border-[#E6E6E6]",
        type === "disabled" && "bg-[#F5F5FF] text-black",
        type === "option" && "bg-[#F5F5FF] text-black hover:bg-[#E8E8F3]"
      )}
    >
      {children}
    </button>
  );
};
