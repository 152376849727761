import { useState } from "react";
import { FaqItem } from "./FaqItem";

const faqItems = [
  {
    title: "برای کسب درآمد از یوتیوب باید چه شرایطی داشت؟",
    value: "شرایط کسب درآمد از یوتیوب را میتوانید در این قسمت مشاهده کنید",
    id: "youtube-earnings-conditions",
  },
  {
    title: "آیا می شود درامد کسب شده در هر ماه به جای دریافت ذخیره کرد؟",
    value:
      "بله، میتوانید درآمد کسب شده در هر ماه را به جای دریافت ذخیره کنید و در ماه های بعدی دریافت کنید",
    id: "earnings-save",
  },
  {
    title: "کارمزد دونو چقدر می باشد؟",
    value:
      "کارمزد دونو برای هر تراکنش 5 درصد می باشد که از مبلغ دریافتی شما کسر می شود",
    id: "dono-commission",
  },
  {
    title: "من در ایران زندگی نمی کنم آیا امکان همکاری با شما وجود دارد؟",
    value:
      "بله، شما میتوانید از هر کجای دنیا که هستید از سیستم دونو استفاده کنید",
    id: "international",
  },
  {
    title: "در صورت معرفی شخصی از طرف من آیا درصد من کم می شود؟",
    value: "خیر، درصد کارمزد دونو برای شخصی که شما معرفی می کنید کم نمی شود",
    id: "referral",
  },
  {
    title: "شرایط دریافت ادسنز اختصاصی به چه صورتی می باشد؟",
    value:
      "شرایط دریافت ادسنز اختصاصی به این صورت می باشد که شما باید حداقل 1000 دنو در یک ماه کسب کنید و همچنین باید حداقل 1000 دنو در یک ماه کسب کنید",
    id: "exclusive-adsense",
  },
  {
    title: "آیا شرکت شما می تواند به من کمک بکند که سریع تر منتایز بشوم؟",
    value:
      "بله، شما میتوانید با تماس با پشتیبانی دونو از این امکان استفاده کنید",
    id: "fast-monetize",
  },
  {
    title: "آیا بعد از شروع همکاری، مالکیت کانال به دونو منتقل خواهد شد؟",
    value:
      "خیر، مالکیت کانال به دونو منتقل نخواهد شد و شما همچنان مالک کانال خواهید بود",
    id: "channel-ownership",
  },
];

export const FaqSection = () => {
  const [activeFaq, setActiveFaq] = useState<any>();

  const toggleFaq = (id: string) => {
    if (activeFaq === id) {
      setActiveFaq(undefined);
    } else {
      setActiveFaq(id);
    }
  };

  return (
    <div className="container mx-auto max-w-7xl">
      <h2 className="font-extrabold text-2xl text-black mb-9 text-center">
        سوالات متداول
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {faqItems.map((item, index) => (
          <a onClick={() => toggleFaq(item.id)}>
            <FaqItem
              key={index}
              title={item.title}
              value={item.value}
              active={activeFaq === item.id}
            />
          </a>
        ))}
      </div>
    </div>
  );
};
